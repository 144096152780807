'use client';

import React, { useImperativeHandle, useRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { useDialogMainSlot } from './DialogMain.utils';

type DialogMainProps = {
  className?: string;
  children?: React.ReactNode;
};

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const DialogMain = React.forwardRef<HTMLElement, DialogMainProps>(
  (props, ref) => {
    const containerRef = useRef<HTMLElement>(null);
    useImperativeHandle(ref, () => containerRef.current as HTMLElement);

    const dialogMainSlotProps = useDialogMainSlot({ ref: containerRef });

    return (
      <article
        ref={containerRef}
        slot="main"
        className={cssMerge(props.className)} // cssMerge is used to ensure className is cleaned up
        {...dialogMainSlotProps}
      >
        {props.children}
      </article>
    );
  },
);

DialogMain.displayName = 'DialogMain';
