'use client';

import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';

export type DialogCloseProps = {
  onClose: (event: any) => void;
  hideCloseButton?: boolean;
  iconButtonVariant?: React.ComponentProps<typeof IconButton>['variant'];
};

export const DialogClose = ({
  onClose,
  hideCloseButton,
  iconButtonVariant,
  ...rest
}: DialogCloseProps) => {
  const translate = useSharedComponentsTranslate();
  const ariaLabel = translate('ReactOverlay.labels.close');
  if (!ariaLabel && process.env.NODE_ENV === 'development') {
    console.error(`Missing dictionaries for aria labels in vcc-package/overlays
If you haven't directly imported the Overlays package, it's probably being used by a component you have imported.
This error occurs when you have not added shared translations from @vcc-www/shared-dictionaries
See https://storybooks.ccdp.io/?path=/docs/dotcom-packages-overlays-docs--docs#prerquisites
`);
  }

  return (
    <div slot="close" {...rest}>
      {!hideCloseButton ? (
        <IconButton
          icon="x"
          aria-label={ariaLabel}
          onClick={onClose}
          variant={iconButtonVariant || 'clear'}
          data-bleed="true"
          className="fade-in"
        />
      ) : null}
    </div>
  );
};
