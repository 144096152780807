'use client';

import React, { useRef } from 'react';
import { useDialogNavigation } from '../../components/dialog-navigation/useDialogNavigation';
import { DialogNavigationProvider } from '../../components/dialog-navigation/DialogNavigationContext';
import { Dialog, type DialogProps } from '../../dialog/Dialog';
import styles from './NavigationDialog.module.css';
import { cssMerge } from '@volvo-cars/css/utils';

export type NavigationDialogProps = Omit<
  DialogProps,
  'showBackButton' | 'title'
> & {
  render: ({
    stack,
    goBack,
    navigate,
  }: ReturnType<typeof useDialogNavigation>) => React.ReactElement;
};

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const NavigationDialogInner = React.forwardRef<
  HTMLDialogElement,
  NavigationDialogProps
>(({ render, ...props }, ref) => {
  const {
    currentPage,
    dirty,
    goBack,
    goHome,
    navigate,
    scrollToPrevScrollPosition,
    previousPage,
    setTitle,
    stack,
    title,
    scrollableElementRef,
  } = useDialogNavigation();
  return (
    <Dialog
      {...props}
      ref={ref}
      title={title}
      onClose={(reason) => {
        goHome();
        props.onClose(reason);
      }}
      showBackButton={stack.length > 1}
      onBackNav={(event) => {
        goBack();
        if (props.onBackNav) {
          props.onBackNav(event);
        }
      }}
      className={cssMerge(
        props.size === 'large' && styles['fixed-height'],
        props.size === 'small' && styles['small-height'],
        props.className,
      )}
    >
      {props.open &&
        render({
          currentPage,
          dirty,
          goBack,
          goHome,
          navigate,
          scrollToPrevScrollPosition,
          previousPage,
          setTitle,
          stack,
          title,
          scrollableElementRef,
        })}
    </Dialog>
  );
});
NavigationDialogInner.displayName = 'NavigationDialogInner';

/**
 * Renders a Navigation Dialog component based off the Dialog component.
 *
 * @component
 * @example
 * ```tsx
 *  <NavigationDialog
 *    open={open}
 *    title="Navigation Dialog"
 *    render={({ stack, goBack, navigate, scrollableElementRef }) => (
 *      <DialogMain ref={scrollableElementRef}>
 *        <DialogNavigationPage pathName="/">
 *          <p>This is the content of the first page in the dialog.</p>
 *          <p>`pathName="/"` is the default page and should always be present.</p>
 *          <button onClick={() => navigate('/page-name')}>Next page</button>
 *        </DialogNavigationPage>
 *        <DialogNavigationPage pathName="/page-name">
 *          <p>This is the content of the second page in the dialog</p>
 *          <button onClick={() => goBack()}>Go back</button>
 *        </DialogNavigationPage>
 *      </DialogMain>
 *    )}
 *  />;
 * ```
 *
 * @param {NavigationDialogProps} props - The props for the NavigationDialog component.
 * @returns {React.ReactElement} The rendered NavigationDialog component.
 */
export const NavigationDialog: React.FC<NavigationDialogProps> = (props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const elementRef = useRef<HTMLElement>(null);
  return (
    <DialogNavigationProvider
      elementRef={dialogRef}
      scrollableElementRef={elementRef}
    >
      <NavigationDialogInner ref={dialogRef} {...props} />
    </DialogNavigationProvider>
  );
};
