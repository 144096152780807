'use client';

import React, { useRef } from 'react';
import { Sheet, type SheetProps } from '../../sheet/Sheet';
import { useDialogNavigation } from '../../components/dialog-navigation/useDialogNavigation';
import { DialogNavigationProvider } from '../../components/dialog-navigation/DialogNavigationContext';

export type NavigationSheetProps = Omit<
  SheetProps,
  'showBackButton' | 'title'
> & {
  render: ({
    stack,
    goBack,
    navigate,
  }: ReturnType<typeof useDialogNavigation>) => React.ReactElement;
};

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const NavigationSheetInner = React.forwardRef<
  HTMLDialogElement,
  NavigationSheetProps
>(({ render, ...props }, ref) => {
  const {
    currentPage,
    dirty,
    goBack,
    goHome,
    navigate,
    previousPage,
    setTitle,
    stack,
    title,
    scrollToPrevScrollPosition,
    scrollableElementRef,
  } = useDialogNavigation();

  return (
    <Sheet
      {...props}
      title={title}
      ref={ref}
      onClose={(reason) => {
        goHome();
        props.onClose(reason);
      }}
      showBackButton={stack.length > 1}
      onBackNav={(event) => {
        goBack();
        if (props.onBackNav) {
          props.onBackNav(event);
        }
      }}
    >
      {props.open &&
        render({
          currentPage,
          dirty,
          goBack,
          goHome,
          navigate,
          previousPage,
          setTitle,
          stack,
          title,
          scrollToPrevScrollPosition,
          scrollableElementRef,
        })}
    </Sheet>
  );
});
NavigationSheetInner.displayName = 'NavigationSheetInner';

/**
 * Renders a Navigation Sheet component based off the Sheet component.
 *
 * @component
 * @example
 * ```tsx
 *  <NavigationSheet
 *    open={open}
 *    title="Navigation Sheet"
 *    render={({ stack, goBack, navigate }) => (
 *      <DialogMain>
 *        <DialogNavigationPage pathName="/">
 *          <p>This is the content of the first page in the dialog.</p>
 *          <p>`pathName="/"` is the default page and should always be present.</p>
 *          <button onClick={() => navigate('/page-name')}>Next page</button>
 *        </DialogNavigationPage>
 *        <DialogNavigationPage pathName="/page-name">
 *          <p>This is the content of the second page in the dialog</p>
 *          <button onClick={() => goBack()}>Go back</button>
 *        </DialogNavigationPage>
 *      </DialogMain>
 *    )}
 *  />;
 * ```
 *
 * @param {NavigationSheetProps} props - The props for the NavigationSheet component.
 * @returns {React.ReactElement} The rendered NavigationSheet component.
 */
export const NavigationSheet: React.FC<NavigationSheetProps> = (props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const elementRef = useRef<HTMLElement>(null);
  return (
    <DialogNavigationProvider
      elementRef={dialogRef}
      scrollableElementRef={elementRef}
    >
      <NavigationSheetInner ref={dialogRef} {...props} />
    </DialogNavigationProvider>
  );
};
